import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Button } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { formatDate, formatNumber } from 'utils/helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoMdCopy } from 'react-icons/io';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';
import { TransactionTypeName } from 'constants/common';

const HistoryTable = ({ tableData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const [, copy] = useCopyToClipboard();

  // const handleRowClick = (ticket, type) => {
  //   handleUpdateCategory(ticket, type);
  // };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'STT',
        cell: info => info.row.index + 1,
      }),
      columnHelper.accessor('gameHistory', {
        header: 'Mã đơn cược',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue()?._id || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue()?._id);
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('brand', {
        header: 'Nhà điều hành',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('member', {
        header: 'Tên HV mã hoá',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue()?.encodeUsername || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue()?.encodeUsername);
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày cược',
        cell: info => (
          <Flex direction="column">
            <Text>{formatDate(info.getValue(), 'DD/MM/YYYY')}</Text>
            <Text>{formatDate(info.getValue(), 'HH:mm:ss')}</Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('amount', {
        header: 'Số tiền',
        cell: info =>
          `${info.getValue() > 0 ? (info?.row?.original?.type === 'WIN_COIN' ? '+' : '-') : ''}${formatNumber(info.getValue() || 0)}`,
      }),
      columnHelper.accessor('preBalance', {
        header: 'Số dư trước',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('currBalance', {
        header: 'Số dư sau',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('type', {
        header: 'Loại giao dịch',
        cell: info => TransactionTypeName?.[info.getValue()],
      }),
      columnHelper.accessor('b', {
        header: 'Chi tiết',
        cell: info => '',
      }),
      columnHelper.accessor('c', {
        header: 'NCC liên quan',
        cell: info => '',
      }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th textAlign="center" key={header.id} minW="120px" bg="gray.100" textColor="#000">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={12}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td textAlign="center" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default HistoryTable;
