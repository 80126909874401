import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListTextSystem = params => request.get('/api/v1/posts', { params });
export const createTextSystem = data => request.post('/api/v1/posts/create', data);
export const updateTextSystem = data => request.post('api/v1/posts/update', data);
export const deleteTextSystem = data => request.post('/api/v1/posts/delete', data);
export const getSettingMaxBet = () => request.get('/api/v1/setting-bet');
export const settingMaxBetAmount = data => request.post('/api/v1/setting-bet/create', data);

// Mutation
export const useCreateTextSystemMutation = () => useMutation({ mutationFn: createTextSystem });
export const useUpdateTextSystemMutation = () => useMutation({ mutationFn: updateTextSystem });
export const useDeleteTextSystemMutation = () => useMutation({ mutationFn: deleteTextSystem });
export const useSettingMaxBetAmountSystemMutation = () => useMutation({ mutationFn: settingMaxBetAmount });

// Query
export const useQueryGetListTextSystem = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_TEXT_SYSTEM', params], queryFn: () => getListTextSystem(params), ...options });
export const useQueryGetSettingMaxBetSystem = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_SETTING_MAX_BET_SYSTEM', params], queryFn: () => getSettingMaxBet(params), ...options });
