import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FileExcelValid, ModalType, PERMISSIONS, SymbolOption } from 'constants/common';
import Pagination from 'components/Pagination/Pagination';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { useQuerGetUsers } from 'services/user';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import CreateSettingModal from './components/CreateSettingModal';
import { useExportSymbolTemplateMutation, useGetConfigSymbolMutation, useQueryGetConfigSymbol } from 'services/symbol';
import SymbolTable from './components/Table';
import { useQueryGetConfigBet } from 'services/setting';
import { Select } from 'chakra-react-select';
import ImportSymbolModal from './components/ImportSymbolModal';

function Symbols() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const location = useLocation();
  const [betConfigOption, setBetConfigOption] = useState([]);
  const [betConfigSelected, setBetConfigSelected] = useState(null);
  const [symbolSelected, setSymbolSelected] = useState(null);
  const [positionSelected, setPositionSelected] = useState(null);
  const [symbolDetail, setSymbolDetail] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { userInfo } = useUserState();

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isImportModalOpen, onOpen: onOpenImportModal, onClose: onCloseImportModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Import]: onOpenImportModal,
    }),
    [onOpenCreateModal, onOpenImportModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Import]: onCloseImportModal,
    }),
    [onCloseCreateModal, onCloseImportModal]
  );
  const isSymbolBonus = useMemo(() => {
    if (location.pathname) {
      return location.pathname.split('/').pop() === 'symbols-bonus';
    }
    return false;
  }, [location?.pathname]);

  const { data: configSymbolData, refetch } = useQueryGetConfigSymbol(
    { ...filter, ...(isSymbolBonus && { type: 'BONUS' }) },
    { enabled: CookieStorage.isAuthenticated() }
  );
  const { data: configBetData } = useQueryGetConfigBet({}, { enabled: CookieStorage.isAuthenticated() });
  const exportTemplateMutation = useExportSymbolTemplateMutation();
  const exportSymbolDataMutation = useGetConfigSymbolMutation(
    {
      isExport: true,
      bet_id: betConfigSelected?.value,
      ...(isSymbolBonus && { type: 'BONUS' }),
    },
    {
      responseType: 'arraybuffer',
    }
  );

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(configBetData)) {
      setBetConfigOption(configBetData?.data?.map(item => ({ label: `${item?.game_id?.name} - ${item?.bet_amount}`, value: item?._id })));
    }
  }, [configBetData?.data]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSymbolDetail(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSymbolDetail(null);
  };

  const onDownloadTemplate = () => {
    exportTemplateMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'symbol-template');
        toast.showMessageSuccess('Tải mẫu Symbol thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu Symbol thất bại');
      },
    });
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      bet_id: betConfigSelected?.value,
      symbol: symbolSelected?.value,
      position: positionSelected?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setBetConfigSelected(null);
    setSymbolSelected(null);
    setPositionSelected(null);
  };

  const onDownloadSymbolData = () => {
    exportSymbolDataMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'symbol-data');
        toast.showMessageSuccess('Xuất dữ liệu Symbol thành công');
      },
      onError: () => {
        toast.showMessageError('Xuất dữ liệu Symbol thất bại');
      },
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column">
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Flex direction="column" gap={'30px'}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                      CÀI ĐẶT TRÒ CHƠI {'>'} TẾT NGUYÊN ĐÁN {'>'} Thiết lập Symbol {isSymbolBonus ? 'Bonus' : ''}
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap="16px">
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                    Tải template
                  </Button>
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      onOpenImportModal();
                    }}
                  >
                    Tải lên thiết lập Symbol
                  </Button>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadSymbolData}>
                    Xuất file excel
                  </Button>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_ADMIN'}>
                    <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Thêm
                      </Text>
                    </Button>
                  </AuthorizationComponentWrapper>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '170px' }}>
                      <FormLabel h={'21px'}>Symbol</FormLabel>
                      <Select
                        placeholder="Chọn symbol"
                        isClearable
                        menuShouldBlockScroll
                        value={symbolSelected}
                        onChange={e => {
                          setSymbolSelected(e);
                        }}
                        options={SymbolOption}
                      />
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '170px' }}>
                      <FormLabel h={'21px'}>Vị trí</FormLabel>
                      <Select
                        placeholder="Chọn vị trí"
                        isClearable
                        menuShouldBlockScroll
                        value={positionSelected}
                        onChange={e => {
                          setPositionSelected(e);
                        }}
                        options={Array.from({ length: 15 }).map((_, index) => ({ label: index, value: index }))}
                      />
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Tỷ lệ cược</FormLabel>
                      <Select
                        placeholder="Chọn trò chơi"
                        isClearable
                        menuShouldBlockScroll
                        value={betConfigSelected}
                        onChange={e => {
                          setBetConfigSelected(e);
                        }}
                        options={betConfigOption}
                      />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SymbolTable settings={configSymbolData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
            {!isEmpty(configSymbolData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={configSymbolData?.pagination?.page}
                  pageLength={configSymbolData?.pagination?.pageSize}
                  totalRecords={configSymbolData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && (
        <CreateSettingModal symbolDetail={symbolDetail} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
      {isImportModalOpen && <ImportSymbolModal isOpen={isImportModalOpen} onClose={handelCloseModal} refetch={refetch} />}
    </>
  );
}

export default Symbols;
