import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GameTypeOption, ModalType } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { GameCodeFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import { useCreateGameMutation } from 'services/game';

const CreateGameModal = ({ games, isOpen, onClose, refetch }) => {
  const [gameOptions, setGameOptions] = useState(GameTypeOption);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(GameCodeFormValidate),
  });

  const createGameMutation = useCreateGameMutation();

  useEffect(() => {
    const newGameOption = GameTypeOption.filter(item => {
      const gameExists = games?.find(itemGame => itemGame?.code === item.value);

      if (!gameExists) return item;
    });

    setGameOptions(newGameOption);
  }, [games]);

  const onSubmit = values => {
    createGameMutation.mutate(
      {
        name: values.type.label,
        code: values.type.value,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo trò chơi thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`Tạo trò chơi`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController control={control} name="type" label="Trò chơi" options={gameOptions} isRequired styleContainer={{ pt: 4 }} />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={createGameMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateGameModal;
