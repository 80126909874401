import { Box, Flex, IconButton, Table, Tbody, Text, Th, Td, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { formatDate } from 'utils/helpers';
import { useDeleteBookmarkerMutation } from 'services/user';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { toast } from 'components/Toast';

const SizeTable = ({ categoryData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteBookMarkerMutation = useDeleteBookmarkerMutation();

  const handleDeleteSize = async bookmarker => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa hệ thống này không?');
    if (!confirmDelete) {
      return;
    }
    deleteBookMarkerMutation.mutate(
      { id: bookmarker?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa hệ thống thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa hệ thống không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('bookMarker', {
        header: 'Tên',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('homepage', {
        header: 'Trang chủ',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('depositPage', {
        header: 'Trang nạp tiền',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),

      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleUpdateCategory(info?.row?.original, ModalType.Add);
              }}
            >
              <EditIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDeleteSize(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={5}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
