// Chakra imports
import { Portal, useDisclosure, Stack, Box, useColorMode } from '@chakra-ui/react';
import Configurator from 'components/Configurator/Configurator';
// import Footer from "components/Footer/Footer.js";
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight } from 'components/Icons/Icons';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
// Custom Chakra theme
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import bgAdmin from 'assets/img/admin-background.png';
import { useUserState } from 'context/UserContext';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Settings from 'views/Dashboard/Settings';
import SymbolReward from 'views/Dashboard/SymbolReward';
import Symbols from 'views/Dashboard/Symbol';
import { IoSettingsOutline } from 'react-icons/io5';
import { Roles } from 'constants/common';

export default function Dashboard(props) {
  const { ...rest } = props;
  const isLoggedIn = CookieStorage.isAuthenticated();
  const listGameStorage = localStorage.getItem('listGame');
  const history = useHistory();
  const { userInfo } = useUserState();
  const [fixed, setFixed] = useState(false);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  document.documentElement.dir = 'ltr';

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const routesMapping = useMemo(() => {
    let gameRoutes = [];

    if (!!listGameStorage) {
      const listGameStorageParsed = JSON.parse(listGameStorage);

      gameRoutes = listGameStorageParsed?.map(item => ({
        name: item.name,
        categoryNested: item.code,
        icon: <IoSettingsOutline size={20} />,
        collapse: true,
        views: [
          {
            path: '/:id/settings',
            pathName: '/settings',
            name: 'Thiết lập tỷ lệ cược',
            layout: '/admin',
            component: Settings,
            id: item.code,
            role: [Roles.ADMIN, Roles.MANAGER],
          },
          {
            path: '/:id/symbols-reward',
            pathName: '/symbols-reward',
            name: 'Trả thưởng Symbol',
            layout: '/admin',
            component: SymbolReward,
            id: item.code,
            role: [Roles.ADMIN, Roles.MANAGER],
          },
          {
            path: '/:id/symbols',
            pathName: '/symbols',
            name: 'Thiết lập Symbol',
            layout: '/admin',
            component: Symbols,
            id: item.code,
            role: [Roles.ADMIN, Roles.MANAGER],
          },
          {
            path: '/:id/symbols-bonus',
            pathName: '/symbols-bonus',
            name: 'Thiết lập Symbol Bonus',
            layout: '/admin',
            component: Symbols,
            id: item.code,
            role: [Roles.ADMIN, Roles.MANAGER],
          },
        ],
      }));
    }

    const gameSettings = routes.find(item => item.category === 'gameSettings');

    gameSettings.views = gameRoutes || [];

    return routes || [];
  }, [routes, listGameStorage]);

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views || []);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views || []);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = routes => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views || []);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop?.categoryNested) {
        return prop.views?.map((viewChild, keyChild) => (
          <Route path={viewChild.layout + viewChild.path} component={viewChild.component} key={keyChild} />
        ));
      }
      if (
        [
          'account',
          'memberManagement',
          'statistics',
          'transactionManagement',
          'gameSettings',
          'gameManagement',
          'systemManagement',
          'adminManagement',
        ].includes(prop.category)
      ) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <Box>
      <Box
        minH="40vh"
        w="100%"
        position="absolute"
        bgImage={colorMode === 'light' ? bgAdmin : 'none'}
        bg={colorMode === 'light' ? bgAdmin : 'navy.900'}
        bgSize="cover"
        top="0"
      />
      <Sidebar
        routes={routesMapping}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === 'dark' ? <ArgonLogoLight w="74px" h="27px" /> : <ArgonLogoDark w="74px" h="27px" />}
            <Box w="1px" h="20px" bg={colorMode === 'dark' ? 'white' : 'gray.700'} />
            {colorMode === 'dark' ? <ChakraLogoLight w="82px" h="21px" /> : <ChakraLogoDark w="82px" h="21px" />}
          </Stack>
        }
        display="none"
        {...rest}
      />
      <MainPanel
        w={{
          base: '100%',
          xl: 'calc(100% - 275px)',
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routesMapping)}
            secondary={getActiveNavbar(routesMapping)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(routesMapping)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
        {/* <Footer /> */}
        {/* <Portal> //
          <FixedPlugin
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal> */}
        <Configurator
          secondary={getActiveNavbar(routesMapping)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={value => {
            setFixed(value);
          }}
        />
      </MainPanel>
    </Box>
  );
}
