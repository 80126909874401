import capitalize from 'lodash/capitalize';
import { convertToTitleCase } from 'utils/helpers';

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
export const ROOT_APP = process.env.REACT_APP_HOST;
export const ADVERTISER_PAGE_URL = process.env.REACT_APP_ADVERTISER_URL;

export const MAX_PHONE_NUMBER_DIGIT = 10;
export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;
export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
};

export const ToastStatus = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const FORMAT_DATE = 'DD-MM-YYYY HH:mm:ss';

export const ContactChannelKey = {
  Facebook: 'facebook',
  Telegram: 'telegram',
  Skype: 'skype',
};

export const ContactChannelOptions = Object.keys(ContactChannelKey).map(key => ({
  label: key,
  value: ContactChannelKey[key],
}));

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const TYPE_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAIL: 'DETAIL',
};

export const TabFeatureProfile = {
  IdentityInfo: 'Identity Info',
  ChangePassword: 'Change Password',
};

export const ModalType = {
  Add: 'add',
  Update: 'update',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  Assign: 'assign',
  Block: 'block',
  Adjust: 'adjust',
  Setting: 'setting',
};

export const FileImageValid = ['png', 'jpeg', 'jpg'];

export const FileExcelValid = ['xls', 'xlsx'];

export const ORDER_STATUS = {
  WAITING_TO_CONFIRM: 'Đang chờ xác nhận',
  REJECTED: 'Đã hủy',
  PROCESSING: 'Đang xử lý',
  APPROVED: 'Đã duyệt',
  SHIPPING: 'Đang giao',
  SUCCESS: 'Giao thành công',
};

export const OrderStatusOption = Object.keys(ORDER_STATUS).map(key => ({
  label: capitalize(ORDER_STATUS[key]),
  value: key,
}));

export const MISSION_TYPE = {
  RECHARGE_IN_DAY: 'RECHARGE_IN_DAY',
  RECHARGE_IN_WEEK: 'RECHARGE_IN_WEEK',
  BET_IN_DAY: 'BET_IN_DAY',
  BET_IN_WEEK: 'BET_IN_WEEK',
};

export const MISSION_TYPE_SUB = {
  RECHARGE_IN_DAY: 'Nạp trong ngày',
  RECHARGE_IN_WEEK: 'Nạp trong tuần',
  BET_IN_DAY: 'Cược trong ngày',
  BET_IN_WEEK: 'Cược trong tuần',
};

export const MissionOption = Object.keys(MISSION_TYPE_SUB).map(key => ({
  label: capitalize(convertToTitleCase(MISSION_TYPE_SUB[key])),
  value: key,
}));

export const BRAND = {
  '2Q': '2Q',
  '888B': '888B',
  '8DAY': '8DAY',
  // STORE: 'STORE',
};

export const BrandFilter = Object.keys(BRAND).map(key => ({
  label: BRAND[key],
  value: key,
}));

const LEVEL_MEMBER = {
  VIP0: 'NORMAL',
  VIP1: 'COPPER',
  VIP2: 'SILVER',
  VIP3: 'GOLD',
  VIP4: 'DIAMOND',
};

export const LevelMemberKkOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const LevelMemberOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: key,
}));

export const LevelMemberOption = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const SHIP_STATUS = {
  211: 'Đang đi lấy hàng',
  203: 'Lấy hàng không thành công',
  202: 'Lấy hàng thành công',
  301: 'Nhận hàng vào bưu cục Nguồn',
  302: 'Xuất hàng đến trung tâm khai thác (trung tâm chia chọn)',
  303: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn)',
  304: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn)',
  309: 'Nhận hàng vào bưu cục phát hàng',
  601: 'Xuất hàng để đi giao',
  604: 'Giao hàng không thành công',
  666: 'Giao hàng thành công',
  605: 'Xác nhận chuyển hoàn',
  701: 'Xuất hàng khỏi bưu cục phát để trả về',
  702: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn) để trả về',
  703: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn) để trả về',
  704: 'Nhận hàng vào bưu cục trả hàng',
  705: 'Xuất hàng để trả về',
  707: 'Trả hàng không thành công',
  708: 'Trả hàng thành công',
  777: 'Thất lạc',
  1000: 'Hư hỏng (Damage)',
  'customer Cancel': 'Khách hàng hủy đơn',
};

export const GAME_TYPE_MISSION = {
  1: 'Nổ hũ',
  2: 'Game Việt',
  3: 'Bắn cá',
  4: 'Casino',
  5: 'Thể thao',
  6: 'Xổ số',
};
export const TYPE_UPDATE_COIN = {
  ADD_COIN: 'Cộng xu',
  SUBTRACT_COIN: 'Trừ xu',
};

export const GameTypesMissionOption = Object.keys(GAME_TYPE_MISSION).map(key => ({
  label: GAME_TYPE_MISSION[key],
  value: key,
}));

export const PostType = {
  Banner: 'BANNER',
  Rules: 'RULES',
  Community: 'COMMUNITY',
  Terms: 'TERMS',
};

export const AdjustCoinType = {
  AddCoin: 'ADD_COIN',
  SubtractCoin: 'SUBTRACT_COIN',
};

export const AdjustCoinOption = [
  {
    label: 'Cộng',
    value: AdjustCoinType.AddCoin,
  },
  {
    label: 'Trừ',
    value: AdjustCoinType.SubtractCoin,
  },
];

export const PERMISSIONS = {
  VIEW_LIST_PRODUCT_CATEGORY: 'Quyền tìm kiếm, xem danh mục sản phẩm',
  VIEW_LIST_CONTENT: 'Quyền tìm kiếm, xem nội dung hệ thống',
  VIEW_LIST_ADMIN: 'Quyền xem danh sách tài khoản admin',
  CREATE_ADMIN: 'Quyền thêm tài khoản admin',
  DELETE_ADMIN: 'Quyền xóa tài khoản admin',
  RESET_PASSWORD_ADMIN: 'Quyền thay đổi mật khẩu tài khoản admin (cá nhân)',
  VIEW_LIST_MEMBER: 'Quyền xem danh sách tài khoản hội viên',
  VIEW_DETAIL_MEMBER: 'Quyền xem thông tin chi tiết hội viên',
  VIEW_HISTORY_RECHARGE_BET_MEMBER: 'Quyền xem lịch sử Nap, Cược của hội viên',
  VIEW_HISTORY_COLLECT_COIN_MEMBER: 'Quyền tìm kiếm, xem lịch sử nhận xu Hội Viên',
  EXPORT_HISTORY_COLLECT_COIN_MEMBER: 'Quyền tải file data lịch sử nhận xu Hội viên',
  VIEW_HISTORY_ORDER_MEMBER: 'Quyền tìm kiếm, xem lịch sử mua hàng của Hội Viên',
  EXPORT_HISTORY_ORDER_MEMBER: 'Quyền tải file lịch sử mua hàng của Hội Viên',
  ADD_SUBTRACT_COIN_MEMBER: 'Quyền bù /trừ xu cho Hội viên',
  VIEW_ADDRESS_MEMBER: 'Quyền xem thông tin địa chỉ nhận hàng',
  UPDATE_PRODUCT_CATEGORY: 'Chỉnh sửa danh mục sản phẩm',
  VIEW_LIST_PRODUCT: 'Quyền tìm kiếm, xem số lượng đã đổi, còn lại của sản phẩm',
  VIEW_DETAIL_PRODUCT: 'Quyền xem chi tiết sản phẩm',
  UPDATE_PRODUCT: 'Quyền chỉnh sửa, thêm/bỏ sản phẩm',
  EXPORT_PRODUCT: 'Quyền tải file data sản phẩm',
  VIEW_DETAIL_MISSION: 'Quyền xem thông tin nhiệm vụ',
  UPDATE_MISSION: 'Quyền Tìm kiếm, Sửa, Thêm/Bỏ nhiệm vụ',
  ACTIVE_MISSION: 'Quyền bật tắt trạng thái hoạt động nhiệm vụ',
  UPDATE_ADDRESS: 'Quyền chỉnh sửa, thêm bỏ địa chỉ gửi và hoàn trả',
  VIEW_LIST_NO_ADDRESS_ORDER: 'Quyền xem thông tin đơn hàng (Không địa chỉ)',
  VIEW_STATUS_ORDER: 'Quyền xem trạng thái đơn hàng',
  CHANGE_STATUS_ORDER: 'Quyền thao tác, chỉnh sửa trạng thái đơn hàng',
  VIEW_DETAIL_ORDER: 'Quyền xem chi tiết đơn hàng',
  SEARCH_ORDER: 'Quyền tìm kiếm đơn hàng (không địa chỉ)',
  CREATE_SHIP_ORDER: 'Quyền tạo đơn vận chuyển',
  VIEW_DETAIL_SHIP_OF_ORDER: 'Quyền xem chi tiết vận chuyển của đơn hàng',
  VIEW_LIST_SHIP_ORDER: 'Quyền xem thông tin vận chuyển đơn hàng',
  VIEW_DETAIL_SHIP_ORDER: 'Quyền xem chi tiết đơn hàng vận chuyển',
  UPDATE_ADDRESS_SHIP_ORDER: 'Quyền cập nhập địa chỉ đơn hàng vận chuyển',
  CANCEL_SHIP_ORDER: 'Quyền hủy đơn hàng vận chuyển',
  UPDATE_LINK_SYSTEM: 'Quyền chỉnh sửa, thêm/xóa link hệ thống',
  UPDATE_NOTIFICATION: 'Quyền thêm/xóa, chỉnh sửa thông báo hệ thống',
  UPDATE_MAINTAIN_SYSTEM: 'Quyền bật/tắt bảo trì hệ thống',
  UPDATE_BANNER: 'Quyền chỉnh sửa, thêm/xóa banner',
  UPDATE_RULES: 'Quyền chỉnh sửa, thêm/xóa thể lệ',
  UPDATE_TERMS: 'Quyền chỉnh sửa, thêm xóa điều khoản',
  UPDATE_POLICY: 'Quyền chỉnh sửa, thêm/xóa tiêu chuẩn cộng đồng',
};

export const PermissionOption = Object.keys(PERMISSIONS).map(key => ({
  label: PERMISSIONS[key],
  value: key,
}));

export const GameTypeOption = [
  {
    label: 'Tết nguyên đán',
    value: 'TET_NGUYEN_DAN',
  },
  {
    label: 'Ẩm thực VN',
    value: 'AM_THUC_VN',
  },
  {
    label: 'Xin chào VN',
    value: 'XIN_CHAO_VN',
  },
  {
    label: 'Khám phá vũ trụ',
    value: 'KHAM_PHA_VU_TRU',
  },
  {
    label: 'Tiệm tạp hoá',
    value: 'TIEM_TAP_HOA',
  },
];

export const SymbolOption = [
  {
    label: 'Symbol_0',
    value: '0',
  },
  {
    label: 'Symbol_1',
    value: '1',
  },
  {
    label: 'Symbol_2',
    value: '2',
  },
  {
    label: 'Symbol_3',
    value: '3',
  },
  {
    label: 'Symbol_4',
    value: '4',
  },
  {
    label: 'Symbol_5',
    value: '5',
  },
  {
    label: 'Symbol scatter',
    value: 'scatter',
  },
  {
    label: 'Symbol wild',
    value: 'wild',
  },
];

export const DateTypeKeyFilter = [
  {
    label: 'Theo ngày',
    value: 'day',
  },
  {
    label: 'Theo tháng',
    value: 'month',
  },
];

export const TransactionTypeName = {
  DEPOSIT: 'Nạp tiền vào ví',
  WITHDRAW: 'Rút tiền về nhà điều hành',
  SUBTRACT_COIN: 'Chơi game',
  WIN_COIN: 'Chơi game',
};
