import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon, SettingsIcon } from '@chakra-ui/icons';
import { FaUserLock } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatDate } from 'utils/helpers';
import { useDeleteUserMutation, useResetPasswordUserMutation } from 'services/user';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useDeleteGameMutation } from 'services/game';

const GameTable = ({ userInfo, games, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const deleteGameMutation = useDeleteGameMutation();

  const handleDeleteGame = async data => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa trò chơi này không?');
    if (!confirmDelete) {
      return;
    }
    deleteGameMutation.mutate(
      { id: data?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa trò chơi thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa trò chơi không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Trò chơi',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('code', {
        header: 'Code',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <IconButton
              bg="transparent"
              onClick={() => {
                history.push(`/admin/game/${info?.row?.original?.code}/free-spin-setting?gameName=${info?.row?.original?.name}`);
              }}
            >
              <SettingsIcon boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDeleteGame(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [games]
  );

  const table = useReactTable({
    data: games || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={3}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default GameTable;
