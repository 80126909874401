import React from 'react';
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import { IoListCircleOutline, IoGameControllerOutline, IoSettingsOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { MdPeopleOutline, MdOutlineSettingsEthernet } from 'react-icons/md';
import { BiFoodMenu } from 'react-icons/bi';
import { FaHistory, FaChartBar } from 'react-icons/fa';
import { RiListSettingsLine, RiMoneyDollarCircleLine } from 'react-icons/ri';
import { IoLogoAndroid } from "react-icons/io";
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Members from 'views/Dashboard/Members/Members';
import Admin from 'views/Dashboard/Admin';
import HistoryMember from 'views/Dashboard/HistoryMember/HistoryMember';
import Settings from 'views/Dashboard/Settings';
import Games from 'views/Dashboard/Games';
import Symbols from 'views/Dashboard/Symbol';
import SymbolReward from 'views/Dashboard/SymbolReward';
import FreeSpinSetting from 'views/Dashboard/FreeSpinSetting';
import StatusSystem from 'views/Dashboard/StatusSystem';
import BookMarker from 'views/Dashboard/BookMarker';
import HistoriesMemberDetail from 'views/Dashboard/HistoryMember/components/Histories';
import Histories from 'views/Dashboard/Members/components/History';
import BrandStatistics from 'views/Dashboard/Statistics/Brand';
import GameStatistics from 'views/Dashboard/Statistics/Game';
import MemberStatistics from 'views/Dashboard/Statistics/Member';
import BetStatistics from 'views/Dashboard/Statistics/Bet';
import CashFlowHistory from 'views/Dashboard/CashFlowHistory';
import TextSystem from 'views/Dashboard/TextSystem';
import WalletHistory from 'views/Dashboard/Members/components/WalletHistory';
import SettingMaxBetAmount from 'views/Dashboard/SettingMaxBetAmount';
import SimulatorPlayGame from 'views/Dashboard/SimulatorPlayGame';

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Trang chủ',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.ADMIN],
  },
  {
    name: 'QUẢN LÝ NGƯỜI CHƠI',
    category: 'memberManagement',
    state: 'pageCollapse',
    collapse: true,
    views: [
      {
        redirect: true,
        path: '/member/:id/wallet-history',
        name: 'Danh sách tài khoản',
        icon: <BiFoodMenu color="inherit" />,
        layout: '/admin',
        component: WalletHistory,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
      },
      {
        redirect: true,
        path: '/member/:id/history',
        name: 'Danh sách tài khoản',
        icon: <BiFoodMenu color="inherit" />,
        layout: '/admin',
        component: Histories,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
      },
      {
        path: '/members',
        name: 'Danh sách tài khoản',
        icon: <BiFoodMenu color="inherit" />,
        layout: '/admin',
        component: Members,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
      },
    ],
  },
  {
    name: 'THỐNG KÊ',
    category: 'statistics',
    state: 'pageCollapse',
    views: [
      {
        path: '/statistics/brand',
        name: 'Nhà điều hành',
        icon: <FaChartBar color="inherit" />,
        layout: '/admin',
        component: BrandStatistics,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: [],
      },
      {
        path: '/statistics/game',
        name: 'Trò chơi',
        icon: <FaChartBar color="inherit" />,
        layout: '/admin',
        component: GameStatistics,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: [],
      },
      {
        path: '/statistics/member',
        name: 'Ngươi chơi',
        icon: <FaChartBar color="inherit" />,
        layout: '/admin',
        component: MemberStatistics,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: [],
      },
      {
        path: '/statistics/bet',
        name: 'Đơn cược',
        icon: <FaChartBar color="inherit" />,
        layout: '/admin',
        component: BetStatistics,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: [],
      },
    ],
  },
  {
    name: 'QUẢN LÝ GIAO DỊCH',
    category: 'transactionManagement',
    state: 'pageCollapse',
    views: [
      {
        path: '/cash-flow-history',
        name: 'Lịch sử dòng tiền',
        icon: <FaHistory color="inherit" />,
        layout: '/admin',
        component: CashFlowHistory,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: [],
      },
    ],
  },
  {
    name: 'CÀI ĐẶT TRÒ CHƠI',
    category: 'gameSettings',
    state: 'pageCollapse',
    collapse: true,
    views: [
      // {
      //   name: 'Tết Nguyên Đán',
      //   categoryNested: 'gameTND',
      //   icon: <IoSettingsOutline size={20} />,
      //   views: [
      //     {
      //       path: '/settings',
      //       name: 'Thiết lập tỷ lệ cược',
      //       layout: '/admin',
      //       component: Settings,
      //       role: [Roles.ADMIN, Roles.MANAGER],
      //     },
      //     {
      //       path: '/symbols-reward',
      //       name: 'Trả thưởng Symbol',
      //       layout: '/admin',
      //       component: SymbolReward,
      //       role: [Roles.ADMIN, Roles.MANAGER],
      //     },
      //     {
      //       path: '/symbols',
      //       name: 'Thiết lập Symbol',
      //       layout: '/admin',
      //       component: Symbols,
      //       role: [Roles.ADMIN, Roles.MANAGER],
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   name: 'QUẢN LÝ GIAO DỊCH',
  //   category: 'gameManagement',
  //   state: 'pageCollapse',
  //   collapse: true,
  //   views: [
  //     {
  //       path: '/history/:id/member',
  //       name: 'Lịch sử chơi',
  //       icon: <FaHistory size={20} />,
  //       layout: '/admin',
  //       component: HistoriesMemberDetail,
  //       redirect: true,
  //       role: [Roles.ADMIN],
  //     },
  //     {
  //       path: '/history',
  //       name: 'Lịch sử chơi',
  //       icon: <FaHistory size={18} />,
  //       layout: '/admin',
  //       component: HistoryMember,
  //       role: [Roles.ADMIN],
  //     },
  //   ],
  // },
  {
    name: 'QUẢN LÝ HỆ THỐNG',
    category: 'systemManagement',
    state: 'pageCollapse',
    collapse: true,
    views: [
      {
        path: '/text-system',
        name: 'Text hiển thị',
        icon: <IoDocumentTextOutline size={20} />,
        layout: '/admin',
        component: TextSystem,
        role: [Roles.ADMIN, Roles.MANAGER],
      },
      {
        path: '/game/:id/free-spin-setting',
        name: 'Thiết lập lượt quay miễn phí',
        layout: '/admin',
        component: FreeSpinSetting,
        role: [Roles.ADMIN, Roles.MANAGER],
        redirect: true,
      },
      {
        path: '/game',
        name: 'Trò chơi',
        icon: <IoGameControllerOutline size={20} />,
        layout: '/admin',
        component: Games,
        role: [Roles.ADMIN, Roles.MANAGER],
      },
      {
        path: '/simulator-play-game',
        name: 'Giả lập lượt chơi',
        icon: <IoLogoAndroid size={20} />,
        layout: '/admin',
        component: SimulatorPlayGame,
        role: [Roles.ADMIN, Roles.MANAGER],
      },
      {
        path: '/setting-bet-amount',
        name: 'Thiết lập tiền cược',
        icon: <RiMoneyDollarCircleLine size={20} />,
        layout: '/admin',
        component: SettingMaxBetAmount,
        role: [Roles.ADMIN, Roles.MANAGER],
      },
      {
        path: '/bookmarker',
        name: 'Quản lý hệ thống',
        icon: <RiListSettingsLine size={20} />,
        layout: '/admin',
        component: BookMarker,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: 'UPDATE_LINK_SYSTEM',
      },
      {
        path: '/status-system',
        name: 'Trạng thái hệ thống',
        icon: <MdOutlineSettingsEthernet size={20} />,
        layout: '/admin',
        component: StatusSystem,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: 'UPDATE_MAINTAIN_SYSTEM',
      },
    ],
  },
  {
    name: 'TÀI KHOẢN QUẢN TRỊ',
    category: 'adminManagement',
    state: 'pageCollapse',
    collapse: true,
    views: [
      {
        path: '/list',
        name: 'Danh sách tài khoản',
        icon: <MdPeopleOutline color="inherit" />,
        layout: '/admin',
        component: Admin,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: 'VIEW_LIST_ADMIN',
      },
      {
        path: '/profile',
        name: 'Bảo mật tài khoản',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
        role: [Roles.ADMIN, Roles.MANAGER, Roles.USER, Roles.GUEST],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
    ],
  },

  // {
  //   path: '/history-collect-coin',
  //   name: 'Lịch sử giao dịch xu',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: HistoryMember,
  //   role: [Roles.ADMIN, Roles.MANAGER],
  //   groupName: 'VIEW_HISTORY_COLLECT_COIN_MEMBER',
  // },
  // {
  //   name: 'ACCOUNT PAGES',
  //   category: 'account',
  //   state: 'pageCollapse',
  //   views: [
  //     {
  //       path: '/sign-in',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignIn,
  //     },
  //     {
  //       path: '/sign-up',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignUp,
  //     },
  //   ],
  // },
  {
    path: '/sign-in',
    layout: '/auth',
    redirect: true,
    component: SignIn,
  },
  {
    path: '/sign-up',
    layout: '/auth',
    redirect: true,
    component: SignUp,
  },
];

export default dashRoutes;
